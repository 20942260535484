import React from "react";
import PageBanner from "../PageBanner/PageBanner";
import "./About.css";
import Background from "./about-banner.jpg";
import { Container, Row, Col, Button, Carousel } from "react-bootstrap";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Subscribe from "../Subscribe/Subscribe";
import Avatar from "@mui/material/Avatar";

function About() {
  const testimonial = [
    {
      content:
        "Over all though it was a great experience and we have had lots of great feedback. We already started promoting our next event and I have been approached by 4 other companies who want to know more about it as they want to use it for their own events.",
      author: "Sarah M, Director of Events",
    },
    {
      content:
        "I cannot tell you how much we loved using this silent auction software. Everything was seamless…from set up, to bidding, to payment. We will absolutely use MyEvent next year.",
      author: "Sarah M, CCHS Foundation",
    },
    {
      content:
        "I tried MyEvent instead of typical paper raffle tickets. The system was easy to set up online and people who couldn't attend the event were still able to enter the raffle, which was HUGE bump in revenue.",
      author: "Alexander B, Pan-Mass Challenge",
    },
    {
      content:
        "MyEvent is a great way to bring in money for your Fund A Need. The 24/7 tech support allows you to feel confident, and the platform makes your Fund a Need so much easier to run. Well definitely be using MyEvent again.",
      author: "Amy C, One Less Orphan Fund",
    },
  ];

  return (
    <div className="about">
      <PageBanner Background={Background} title="About Us" />
      <div className="container py-5 mt-5">
        <div className="row about-bg mt-2">
          <div className="col-lg-3 flex-style">
            <h1 className="sub-heading">Who we are?</h1>
            {/* <h1
              className="section-heading"
              style={{ fontWeight: "800", fontSize: "3rem" }}
            >
              Elite
            </h1> */}
          </div>
          <div className="col-lg-9">
            <section id="conference-timeline">
              {/* <div class="timeline-start">Start</div> */}
              <div class="conference-center-line"></div>
              <div class="conference-timeline-content">
                <div class="timeline-article">
                  <div class="content-right-container">
                    <div class="content-right">
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Founded in 1993, Elite Freight Solutions
                        </span>{" "}
                        has since grown into a market leader within the
                        logistics industry servicing customers across various
                        market segments. Since its inception, Elite Freight has
                        forged strong alliances with the Australian carrier
                        network and its international partners benefiting its
                        customers through the supply of a fully integrated
                        logistics offering, whilst also improving the commercial
                        outcomes and improved services on behalf of its
                        customers.
                      </p>
                    </div>
                  </div>
                  <div class="meta-date">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="rocket"
                      class="svg-inline--fa fa-rocket fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width={"33px"}
                    >
                      <path
                        fill="#eb1c24"
                        d="M505.12019,19.09375c-1.18945-5.53125-6.65819-11-12.207-12.1875C460.716,0,435.507,0,410.40747,0,307.17523,0,245.26909,55.20312,199.05238,128H94.83772c-16.34763.01562-35.55658,11.875-42.88664,26.48438L2.51562,253.29688A28.4,28.4,0,0,0,0,264a24.00867,24.00867,0,0,0,24.00582,24H127.81618l-22.47457,22.46875c-11.36521,11.36133-12.99607,32.25781,0,45.25L156.24582,406.625c11.15623,11.1875,32.15619,13.15625,45.27726,0l22.47457-22.46875V488a24.00867,24.00867,0,0,0,24.00581,24,28.55934,28.55934,0,0,0,10.707-2.51562l98.72834-49.39063c14.62888-7.29687,26.50776-26.5,26.50776-42.85937V312.79688c72.59753-46.3125,128.03493-108.40626,128.03493-211.09376C512.07526,76.5,512.07526,51.29688,505.12019,19.09375ZM384.04033,168A40,40,0,1,1,424.05,128,40.02322,40.02322,0,0,1,384.04033,168Z"
                      ></path>
                    </svg>
                  </div>
                </div>

                <div class="timeline-article">
                  <div class="content-right-container">
                    <div class="content-right">
                      <p>
                        In August of 2020, saw the re birth and re branding of
                        the Elite business and new entity under Elite Freight
                        Solutions <span style={{ fontWeight: "bold" }}>Australia</span> and re structure of its senior
                        management team and board of directors. The re structure
                        enabled access to new market segments and distribution
                        networks, including greater resources, capacity, and
                        specialised staff. Our capabilities and innovative
                        approach continue to supply services, which exceed our
                        customer’s expectations.
                      </p>
                    </div>
                  </div>
                  <div class="meta-date">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle"
                      class="svg-inline--fa fa-circle fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width={"33px"}
                    >
                      <path
                        fill="#eb1c24"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                  </div>
                </div>

                <div class="timeline-article">
                  <div class="content-right-container">
                    <div class="content-right">
                      <p>
                        Our growth to date and plans revolves around supplying
                        personalised service levels at competitive prices, to
                        all entities. We believe that by maintaining and
                        exceeding customer’s expectations will we only then
                        enjoy further growth. Our team respect and embrace our
                        values to achieve customer excellence and satisfaction
                        at all times.
                      </p>
                    </div>
                  </div>
                  <div class="meta-date">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle"
                      class="svg-inline--fa fa-circle fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width={"33px"}
                    >
                      <path
                        fill="#eb1c24"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              {/* <div class="timeline-end">End</div> */}
            </section>
          </div>
        </div>
      </div>
      <Subscribe />
    </div>
  );
}

export default About;
