import React, { useState } from "react";
import "./Footer.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Col, Container, Row } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { useFormik } from "formik";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

function Footer() {
  const [isSubmitted, setIsSubmitted] = useState(false)
  // const validate = (values) => {
  //   const errors = {};

  //   if (!values.email) {
  //     errors.email = "Required";
  //   } else if (
  //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  //   ) {
  //     errors.email = "Invalid email address";
  //   }

  //   return errors;
  // };

    const notify = ()=>{
        toast.error('Subscribed successfully', {
         // Set to 15sec
         position: toast.POSITION.BOTTOM_CENTER, autoClose:5000, hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,})
    }

  // const formik = useFormik({
  //   initialValues: {
  //     email: "",
  //   },
  //   validate,
  //   onSubmit: (values) => {
  //     // alert(JSON.stringify(values, null, 2));
  //     notify();
  //   },
  // });

  const formHandler = () => {
    setIsSubmitted(true);
    console.log('done');
    window.location.href = '/thankyou';
  }

  return (
    <div className="elite__footer">
      <Container fluid>
        {/* <Row>
          <hr className="footer-hr-top"/>
        </Row> */}
        <Row>
          {/* <div className='footer'> */}
          <Col lg={1} md={6} sm={12} className="flex-style footer-1">
            <div className="footer__logo flex-style">
              <img className="logo__image py-1" src="./images/elitelogo.jpg" alt="" />
            </div>
          </Col>
          <Col lg={3} md={6} sm={12} className="flex-style footer-2" style={{justifyContent: 'center', alignItems: 'flex-start',}}>
            <div className="footer__left">
            <p>
                  <span className="company-p">Elite Freight Solutions Australia Pty Ltd,</span>
                    <br />
                    9/19, Norwood Crescent, <br/>
                    Moonee Ponds, <br/> VIC , 3039, <br/>Australia
              </p>
            
            <p><svg xmlns="http://www.w3.org/2000/svg" width="21.017" height="21.018" viewBox="0 0 21.017 21.018">
  <path id="Icon_awesome-phone" data-name="Icon awesome-phone" d="M20.253,1.009,15.984.024a.992.992,0,0,0-1.129.571l-1.97,4.6a.983.983,0,0,0,.283,1.149l2.488,2.036a15.213,15.213,0,0,1-7.274,7.274L6.346,13.164A.984.984,0,0,0,5.2,12.881L.6,14.851a1,1,0,0,0-.575,1.133l.985,4.269a.985.985,0,0,0,.961.764A19.045,19.045,0,0,0,21.017,1.97.984.984,0,0,0,20.253,1.009Z" transform="translate(0.001 0.001)" fill="#eb1c24"/>
</svg><span style={{paddingLeft: '0.5rem'}}>+61 3 9242 0143</span>
</p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={12}></Col>
          <Col lg={3} md={6} sm={12} className="footer-right-div footer-3">
            <div className="email-col">
                <div className="row px-2 pb-5">
                <div className="col-10 p-0">
                  
                  <form method="post" id="gform" action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfYArfIfBAoSp8XGda8EEkod_ZZ_jXjvXremF153gNddhptQg/formResponse" target="hidden_iframe" onSubmit={formHandler}>
                    <input
                      type="email"
                      className="email__sub2"
                      placeholder="Email.."
                      id="email"
                      name="entry.1561798625"
                      required
                    />
                <div className="flex-style pt-4"><button className="btn btn-lg btn-danger contact__button" style={{borderRadius: '25px'}}>
                  Get in touch!
                </button></div>
                    </form>
                  </div>
                  <div className="col-2 flex-style svg-col p-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="315.22"
                      height="212.781"
                      viewBox="0 0 315.22 212.781"
                      className="email-svg"
                    >
                      <g
                        id="Group_439"
                        data-name="Group 439"
                        transform="translate(-1196.781 -5395)"
                      >
                        <path
                          id="Icon_zocial-email"
                          data-name="Icon zocial-email"
                          d="M.072,198V22.914q0-.3.912-5.775l103.047,88.152L1.288,204.082A25.761,25.761,0,0,1,.072,198ZM13.751,4.98a13.1,13.1,0,0,1,5.168-.912H296.445a17.212,17.212,0,0,1,5.471.912L198.566,93.436l-13.679,10.943-27.054,22.19-27.054-22.19L117.1,93.436Zm.3,210.957,103.655-99.4,40.124,32.525,40.124-32.525,103.655,99.4a14.6,14.6,0,0,1-5.168.912H18.918a13.767,13.767,0,0,1-4.864-.912ZM211.637,105.291,314.38,17.139a18.143,18.143,0,0,1,.912,5.775V198a23.3,23.3,0,0,1-.912,6.079Z"
                          transform="translate(1196.709 5390.932)"
                          fill="#111111"
                        />
                      </g>
                    </svg>
                  </div>
                </div>

                
              </div>
          </Col>
          <Col lg={2} md={6} sm={12} className="footer-right-div flex-style footer-4" style={{justifyContent: 'flex-start'}}>
            <div className="footer__right">
              <div className="social-icons">
              <a href="https://www.linkedin.com/company/elite-freight-solutions-australia"><div className="social__icon__footer">
                <LinkedInIcon className="svg_icons" id="linkedin" />
                <p className="footer__media__icons"></p>
              </div></a>
              
              <a href="https://www.facebook.com/EliteFSA"><div className="social__icon__footer">
                <FacebookIcon className="svg_icons" id="facebook"/>
                <p className="footer__media__icons"></p>
              </div></a>
              
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <hr className="footer-hr-bottom"/>
        </Row>
      </Container>
      <Container>
        <Row className="flex-style pt-5 mt-2">
          <div className="copyright-div flex-style"><p>&#169; 2022 Elite Freight Solutions Australia. All rights reserved. <Link to="/privacypolicy">Privacy Policy</Link></p></div>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
