import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./PageBanner.css";
import PhoneIcon from "@mui/icons-material/Phone";

function PageBanner({ Background, title }) {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container fluid>
          <Navbar.Brand>
            <Link to="/">
              <img
                className="elite__logo"
                src="./images/elitelogo.jpg"
                alt="logo"
              />
              {/* <img className='elite__logo' src='/images/Elite-BC.webp' alt='logo' /> */}
            </Link>
            <a className="hyper-login" target="_blank" href="http://www.flagshiptms.com/">
                  <img
                className="elite__logo flag_ship"
                src="./images/flagship-logo.png"
                alt="logo"
              />
              </a>
            
            <a className="hyper-login" target="_blank" href="http://www.flagshiptms.com/">Flagship Log In</a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Link to="/" className="home__nav">
                Home
              </Link>
              <Link to="/about" className="home__nav">
                About
              </Link>
              <Link to="/services" className="home__nav">
                Services
              </Link>
              <Link to="/technology" className="home__nav">
                Technology
              </Link>
              <Link to="/network" className="home__nav">
                Network
              </Link>
              <Link to="/contact" className="home__nav">
                Contact
              </Link>
              <a href="tel:+61392420143" className="home__nav">
                <button className="btn btn-sm btn-danger btn-contact p-2">
                  +61 3 9242 0143
                </button>
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="common__page__banner">
      <div
        className="page__banner"
        style={{
          backgroundImage:
            "url(" +
            Background +
            "), linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3))",
          backgroundBlendMode: "overlay",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "42vw"
        }}
      >
        <div className="pageBanner flex-style">
          <div className="banner__title">
            <h1 className="page__banner__main__title">{title}</h1>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default PageBanner;
