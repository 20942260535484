import React from "react";
import PageBanner from "../PageBanner/PageBanner";
import "./Technology.css";
import Background from "./technology-banner.jpg";
import { Container, Row, Col, Button, Carousel } from "react-bootstrap";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Subscribe from "../Subscribe/Subscribe";
import Avatar from "@mui/material/Avatar";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import flagship from './flagship.svg'

function Technology() {
  const testimonial = [
    {
      content:
        "Over all though it was a great experience and we have had lots of great feedback. We already started promoting our next event and I have been approached by 4 other companies who want to know more about it as they want to use it for their own events.",
      author: "Sarah M, Director of Events",
    },
    {
      content:
        "I cannot tell you how much we loved using this silent auction software. Everything was seamless…from set up, to bidding, to payment. We will absolutely use MyEvent next year.",
      author: "Sarah M, CCHS Foundation",
    },
    {
      content:
        "I tried MyEvent instead of typical paper raffle tickets. The system was easy to set up online and people who couldn't attend the event were still able to enter the raffle, which was HUGE bump in revenue.",
      author: "Alexander B, Pan-Mass Challenge",
    },
    {
      content:
        "MyEvent is a great way to bring in money for your Fund A Need. The 24/7 tech support allows you to feel confident, and the platform makes your Fund a Need so much easier to run. Well definitely be using MyEvent again.",
      author: "Amy C, One Less Orphan Fund",
    },
  ];

  return (
    <div className="technology">
      <PageBanner Background={Background} title="Technology" />
      <div className="ellipse__elite__logo">
        <div className="elite__aboutus pt-5">
          <h3 style={{ lineHeight: "1.5", paddingTop: '1.75rem' }}>
            Manage your end-to-end supply chain <br /> with our world class
            proprietary technology.{" "}
          </h3>
          <hr className="hr__small" />
        </div>
        <div className="">
          <Container className="">
            <Row>
              <Col lg={6} md={6} sm={12} className="flex-style" style={{justifyContent: 'flex-start'}}>
              <a style={{width: "100%"}} target="_blank" href="http://www.flagshiptms.com/"><img className="" src={flagship} width={'100%'}/></a>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                className="img__detail__about flex-style px-5"
              >
                <p className="text__justify" style={{ lineHeight: "1.75",paddingTop: '7.5%' }}>
                Flagship is our landmark cloud-based online system which puts you in control of all your logistics needs – from booking to printing labels to tracking, analytics and reporting. Manage all your curated carriers in one place. Measure the carrier performance against key KPIs and keep track of your full logistics solutions with our real time reporting. Any time, any place – access everything you need.
<br/></p>
<p className="text__justify" style={{ lineHeight: "1.75" }}>
Unlike most other systems, we provide a tailored solution to manage your supply chain needs. What that means – we take the time to understand the technology used within your supply chain – we actively integrate with warehouse management systems providing a seamless end to end solution.
                </p>
              </Col>
            </Row>
            <hr className="about__hr__tag" />
          </Container>
        </div>
        <div className="testimonials-tech">
          <Container>
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              items={1}
              autoplay={"true"}
            >
              <div class="item">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 tech-carousel-bg">
                      <img
                        src="./images/carousel-1.jpg"
                        alt=""
                        className="tech-carousel-img"
                      />
                    </div>
                    <div className="col-lg-6 flex-style carousel-col">
                      <div className="carousel-content">
                        <h6 className="carousel-heading">
                          Manage your shipments in one spot
                        </h6>
                        <p className="carousel-p">
                          Book all your consignments with your curated list of
                          carriers with a live freight calculator.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 tech-carousel-bg">
                      <img
                        src="./images/carousel-2.jpg"
                        alt=""
                        className="tech-carousel-img"
                      />
                    </div>
                    <div className="col-lg-6 flex-style carousel-col">
                      <div className="carousel-content">
                        <h6 className="carousel-heading">
                          Print all your documentation
                        </h6>
                        <p className="carousel-p">
                          Submit consignments and manifest all in one place.
                          Print carrier-approved labels with our carrier
                          integrations.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 tech-carousel-bg">
                    
                      <img
                        src="./images/carousel-3.jpg"
                        alt=""
                        className="tech-carousel-img"
                      />
                     
                    </div>
                    <div className="col-lg-6 flex-style carousel-col">
                      <div className="carousel-content">
                        <h6 className="carousel-heading">Full Visibility</h6>
                        <p className="carousel-p">
                          Flagship is your home for all order visibility. View
                          scanned events and update your customers in real time.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 tech-carousel-bg">
                      <img
                        src="./images/carousel-4.jpg"
                        alt=""
                        className="tech-carousel-img"
                      />
                    </div>
                    <div className="col-lg-6 flex-style carousel-col">
                      <div className="carousel-content">
                        <h6 className="carousel-heading">
                          Data at your fingertips
                        </h6>
                        <p className="carousel-p">
                          Measure your KPIs , analyse your choice of data and
                          receive all your billings in one place. Match your
                          Financials with your data.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 tech-carousel-bg">
                      <img
                        src="./images/carousel-5.jpg"
                        alt=""
                        className="tech-carousel-img"
                      />
                    </div>
                    <div className="col-lg-6 flex-style carousel-col">
                      <div className="carousel-content">
                        <h6 className="carousel-heading">
                          We integrate with you
                        </h6>
                        <p className="carousel-p">
                          We come to you, we understand your full supply chain
                          environment and we integrate with you; creating the
                          most seamless supply chain provider in the market.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </Container>
        </div>
        <div className="container benefits py-5 mt-5">
          <div className="row">
            <div className="col-lg-4 benefits-left flex-style">
              <h3 className="benefits-h2">Benefits of Our</h3>
              <h1 className="benefits-h1">Technology</h1>
            </div>
            <div className="col-lg-8 benefits-right">
              <div className="row benefits-box">
                <div className="col-2 flex-start-custom">
                  <img
                    src="./images/458.png"
                    alt=""
                    className="benefits-icon"
                  />
                </div>
                <div className="col-10">
                  <h5 className="benefits-h5">One stop shop Carrier Portal</h5>
                  <hr className="benefits-hr" />
                  <p className="benefits-p">
                    We integrate with every carrier in our network,
                    this creates your integrated logistics management solutions
                    right at the tip of your fingers. Book your jobs, find your
                    live rate comparisons, consolidate freight and dispatch
                    shipments across multiple carriers. You will have full
                    visibility over your shipments with our integrated track and
                    trace functionality. Never miss a beat!
                  </p>
                </div>
              </div>
              <div className="row benefits-box">
                <div className="col-2 flex-start-custom">
                  <img
                    src="./images/459.png"
                    alt=""
                    className="benefits-icon"
                  />
                </div>
                <div className="col-10">
                  <h5 className="benefits-h5">Keep track of performance</h5>
                  <hr className="benefits-hr" />
                  <p className="benefits-p">
                    We provide functional reporting and detailed analytics that
                    ensure you have visibility over the performance and your
                    operations. Segment by carrier, leg, and service. Make
                    Flagship your KPI hub.
                  </p>
                </div>
              </div>
              <div className="row benefits-box">
                <div className="col-2 flex-start-custom">
                  <img
                    src="./images/460.png"
                    alt=""
                    className="benefits-icon"
                    style={{ width: "40%" }}
                  />
                </div>
                <div className="col-10">
                  <h5 className="benefits-h5">Multi User Roles</h5>
                  <hr className="benefits-hr" />
                  <p className="benefits-p">
                    Segment your account user by user. We allow for your
                    business and our system to be segmented by roles so that
                    each person has the desired functionality within our
                    customer’s business. From warehouse manager to CFO to
                    different sites – see what you need.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Subscribe />
      </div>
    </div>
  );
}

export default Technology;
