import React from "react";
import PageBanner from "../PageBanner/PageBanner";
import "./Network.css";
import Background from "./network-banner.png";
import { Container, Row, Col, Button, Carousel } from "react-bootstrap";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Subscribe from "../Subscribe/Subscribe";
import Avatar from "@mui/material/Avatar";

function Network() {
  const testimonial = [
    {
      content:
        "Over all though it was a great experience and we have had lots of great feedback. We already started promoting our next event and I have been approached by 4 other companies who want to know more about it as they want to use it for their own events.",
      author: "Sarah M, Director of Events",
    },
    {
      content:
        "I cannot tell you how much we loved using this silent auction software. Everything was seamless…from set up, to bidding, to payment. We will absolutely use MyEvent next year.",
      author: "Sarah M, CCHS Foundation",
    },
    {
      content:
        "I tried MyEvent instead of typical paper raffle tickets. The system was easy to set up online and people who couldn't attend the event were still able to enter the raffle, which was HUGE bump in revenue.",
      author: "Alexander B, Pan-Mass Challenge",
    },
    {
      content:
        "MyEvent is a great way to bring in money for your Fund A Need. The 24/7 tech support allows you to feel confident, and the platform makes your Fund a Need so much easier to run. Well definitely be using MyEvent again.",
      author: "Amy C, One Less Orphan Fund",
    },
  ];

  return (
    <div className="about">
      <PageBanner Background={Background} title="Our Network" />
      <div className="container pt-5 mt-5">
        <div className="row">
          <div className="col-12">
            <h3 className="sub-heading" style={{lineHeight: '2'}}>How we do it?</h3>
            <h1
              className="section-heading"
              style={{ fontWeight: "800", fontSize: "3rem", textAlign: 'left', lineHeight: '1.5' }}
            >
              Elite's Network
            </h1>
            <p style={{lineHeight: '2'}}>
Our industry experience and longevity has created long lasting carrier relationships that provides our customers with an unparalleled service. 
</p>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row mt-2">
          <div className="col-lg-7 flex-style">
            <img src="./images/world-map.svg" alt="" width={'100%'}/>
          </div>
          <div className="col-lg-5">
            <section id="conference-timeline">
              {/* <div class="timeline-start">Start</div> */}
              <div class="conference-center-line"></div>
              <div class="conference-timeline-content">
                <div class="timeline-article">
                  <div class="content-right-container">
                    <div class="content-right">
                      <p>
                        We have fostered long standing relationships with over 100 carriers in order to provide a vast array of services, timely customer service and superior cost savings to our customer base. 
                      </p>
                    </div>
                  </div>
                  <div class="meta-date">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle"
                      class="svg-inline--fa fa-circle fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width={"33px"}
                    >
                      <path
                        fill="#eb1c24"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                  </div>
                </div>

                <div class="timeline-article">
                  <div class="content-right-container">
                    <div class="content-right">
                      <p>
                        We leverage our relationships and utilize our experience to provide our customers with the most optimal carrier mix to ensure the efficiency and effectiveness of customer supply chain needs are not only met; but they are enhanced. 
                      </p>
                    </div>
                  </div>
                  <div class="meta-date">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle"
                      class="svg-inline--fa fa-circle fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width={"33px"}
                    >
                      <path
                        fill="#eb1c24"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                  </div>
                </div>

                <div class="timeline-article">
                  <div class="content-right-container">
                    <div class="content-right">
                      <p>
                        There is no job too big or too small, there is no place too far or too close. Our flexibility is what sets us apart. <br/>Look at our global and national footprint.
                      </p>
                    </div>
                  </div>
                  <div class="meta-date">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle"
                      class="svg-inline--fa fa-circle fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width={"33px"}
                    >
                      <path
                        fill="#eb1c24"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              {/* <div class="timeline-end">End</div> */}
            </section>
          </div>
        </div>
      </div>
      <Subscribe />
    </div>
  );
}

export default Network;
