import React, { useEffect, useState } from "react";
import "./ThankYou.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";

function ThankYou() {
  const [redirect, setRedirect] = useState(false);  
    useEffect(() => {
        setTimeout(() => {
            setRedirect(true)
        }, 5000)
    }, [])
    return (
      <>
    {!redirect ? (
      <div>
          <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container fluid>
          <Navbar.Brand>
            <Link to="/">
              <img
                className="elite__logo"
                src="/images/elitelogo.jpg"
                alt="logo"
              />
              {/* <img className='elite__logo' src='/images/Elite-BC.webp' alt='logo' /> */}
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Link to="/" className="home__nav">
                Home
              </Link>
              <Link to="/about" className="home__nav">
                About
              </Link>
              <Link to="/services" className="home__nav">
                Services
              </Link>
              <Link to="/technology" className="home__nav">
                Technology
              </Link>
              <Link to="/network" className="home__nav">
                Network
              </Link>
              <Link to="/contact" className="home__nav">
                Contact
              </Link>
              <a href="tel:+61392420143" className="home__nav">
                <button className="btn btn-sm btn-danger btn-contact p-2">
                  +61 3 9242 0143
                </button>
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
         <div className="jumbotron text-center flex-style thanks-bg" style={{height: '67vh'}}>
  <h1 className="display-3">Thank You!</h1>
  <hr/>
  <p className="lead text-center">You will be automatically <strong>redirected</strong> to our home page.</p>
</div>  
    </div>
  ) : (
        <Redirect to="/" />
      )}
      </>
  );
};

export default ThankYou;
