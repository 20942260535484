import React from "react";
import PageBanner from "../PageBanner/PageBanner";
import "./Contact.css";
import Background from "./img_contactUs.png";
import { Col, Container, Row } from "react-bootstrap";
import RoomIcon from "@mui/icons-material/Room";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import EmailIcon from "@mui/icons-material/Email";
import Form from "../Form/Form";

function Contact() {
  return (
    <div className="contact">
      <PageBanner Background={Background} title="Contact Us"/>
      <div className="contact__details py-5 mt-5">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className="contact__address">
                <h4>Contact Us</h4>
                <hr className="hr__contact" />
                <div className="contact__address__location">
                  <RoomIcon className="room__icon" />
                  <p>
                  Elite Freight Solutions Australia Pty Ltd
                    <br />
                    9/19 Norwood Crescent, <br/>
Moonee Ponds, VIC , 3039, <br/> Australia
                  </p>
                </div>
              </div>
              <div className="contact__support__details">
                <div className="contact__support">
                  <HeadsetMicIcon className="headset__mic" />
                  <p>
                  Reach us on any queries
                    <br />
                    <b>+61 3 9242 0143</b>
                  </p>
                </div>
              </div>
              <div className="contact__mail__details">
                <div className="contact__mail">
                  <EmailIcon className="room__icon" />
                  <p>
                    Mail your queries and proposals to
                    <br />
                    <b>sales@elitefsa.com</b>
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className="elite__map">
                <iframe
                  title="elite-map"
                  style={{ border: '0', width: "100%", height: "400px" }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50464.69040553163!2d144.8835412791016!3d-37.7655864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65cf66c963ddb%3A0x571f61b2debe887a!2sElite%20Freight%20Solutions!5e0!3m2!1sen!2sin!4v1644265429900!5m2!1sen!2sin"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Form />
    </div>
  );
}

export default Contact;
