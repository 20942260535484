import React from "react";
import "./Subscribe.css";
import EmailIcon from "@mui/icons-material/Email";
import { Button } from "@mui/material";
import { Container } from "react-bootstrap";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

function Subscribe() {
  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <div className="subscribe">
      <div className="get__in__touch">
        <div className="get__in__touch__head container-fluid">
          <div className="row">
            <div className="col-12 get-in-col1 flex-style">
              <h2 className="talk">Contact us</h2>
              <hr className="red-hr"/>
              <h1 className="get-in-h1">
              now for your free <br/> supply chain appraisal!
              </h1>
              <Link to="/contact"><button className="btn btn-lg btn-danger get-in-touch-btn">
                  Get in touch!
                </button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



export default Subscribe;
