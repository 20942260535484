import React, { useEffect, useState } from "react";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Home";
import { HashRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import Contact from "./Components/Contact/Contact";
import Services from "./Components/Services/Services";
import Technology from "./Components/Technology/Technology";
import About from "./Components/About/About";
import LoadingScreen from "./Components/LoadingScreen/LoadingScreen";
import Subscribe from "./Components/Subscribe/Subscribe";
import Network from "./Components/Network/Network";
import ThankYou from "./Components/ThankYou/ThankYou";
import "./App.css";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  });

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}


  return (
    <>
      {!loading ? (
        <Router>
          <ScrollToTop />
          <div className="App">
            <Switch>
              <Route exact path="/">
                <Home />
                <Subscribe />
                <Footer />
                {/* <ScrollToTop /> */}
              </Route>
              <Route path="/about">
                <About />
                <Footer />
              </Route>
              <Route path="/privacypolicy">
                <PrivacyPolicy />
                <Footer />
              </Route>
              <Route path="/contact">
                <Contact />
                <Footer />
              </Route>
              <Route path="/services">
                <Services />
                <Footer />
              </Route>
              <Route path="/technology">
                <Technology />
                <Footer />
              </Route>
              <Route path="/network">
                <Network />
                <Footer />
              </Route>
              <Route path="/thankyou">
                <ThankYou />
                <Footer />
              </Route>
            </Switch>
          </div>
        </Router>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}

export default App;
