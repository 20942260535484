import React from 'react'

function LoadingScreen() {
    return (
        <div className='loading__screen'>
            <div className='gif__load'>
                <img style={{width:'100%'}} src='./images/loading.gif' />
            </div>
        </div>
    )
}

export default LoadingScreen
