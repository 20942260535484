// import { Button, Grid } from '@mui/material'
import React from "react";
import "./Home.css";
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Subscribe from "../Subscribe/Subscribe";
import Banner from "../Banner/Banner";
import { Col, Container, Row } from "react-bootstrap";
import { Player, BigPlayButton } from "video-react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";

function Home() {
  return (
    <div className="home__elite">
      <Banner />
      <div className="home__section">
        <Container>
          <Row>
            <Col lg={3} md={6} sm={12}>
              <ScrollAnimation animateIn="fadeIn">
                <div className="box__shadow">
                  <img
                    className="img__icon"
                    src="./images/icon_logistics.png"
                    alt="icon"
                  />
                  <h5 className="box-h1">
                    Personalised and <br /> Nimble
                  </h5>
                  <p className="box-p">
                    High level carrier relationships ensuring timely action and
                    response.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>

            <Col lg={3} md={6} sm={12}>
              <ScrollAnimation animateIn="fadeIn" delay={"500"}>
                <div className="box__shadow">
                  <img
                    className="img__icon"
                    src="./images/icon_manager.png"
                    alt="icon"
                  />
                  <h5 className="box-h1">
                    Supply Chain <br />
                    Appraisals
                  </h5>
                  <p className="box-p">
                    In depth lane by lane analysis backed by robust and
                    comprehensive implementation.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>

            <Col lg={3} md={6} sm={12}>
              <ScrollAnimation animateIn="fadeIn" delay={"750"}>
                <div className="box__shadow">
                  <img
                    className="img__icon"
                    src="./images/icon_handshake.png"
                    alt="icon"
                  />
                  <h5 className="box-h1">
                    Flexible &amp; Tailored <br /> Freight Options
                  </h5>
                  <p className="box-p">
                    Tailored solutions regardless of profile or commodity. Over
                    100+ carriers in our network.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>

            <Col lg={3} md={6} sm={12}>
              <ScrollAnimation animateIn="fadeIn" delay={"1000"}>
                <div className="box__shadow">
                  <img
                    className="img__icon"
                    src="./images/icon_headphones.png"
                    alt="icon"
                  />
                  <h5 className="box-h1">
                    Tailored <br /> Technology
                  </h5>
                  <p className="box-p">
                    Multi Carrier dispatch system integrated across our customer’s
                    supply chain and tailored to their needs.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </div>
      {/* VIDEO */}
      {/* <iframe
            style={{width:'560',height:'500'}}
             src="https://www.youtube.com/embed/ugpkAwjVXQo" 
            title="YouTube video player" 
            frameborder="0" allow="accelerometer;clipboard-write; encrypted-media; 
            gyroscope; picture-in-picture" allowfullscreen></iframe> */}
      {/* VIDEO END */}
      {/* <div className="what__we__are">
        <h3>Elite</h3>
        <hr className="hr__small" />
        <h1>What We are?</h1>

        <Container className="video__elite">
          <ReactPlayer
            controls
            playing
            playIcon={
              <img
                style={{ height: "100px" }}
                src="/images/play with shadows.png"
              />
            }
            //light="https://i.stack.imgur.com/zw9Iz.png"
            light="/images/whatweare.png"
            url="https://youtu.be/ugpkAwjVXQo"
          />
        </Container>

      </div>
       <div className='elite__services'>
                <h3>Services</h3>
                <hr className='hr__small' />
                <h1>We offer different services</h1>
                <Grid container spacing={4}>
                    <Grid item lg={6} md={6} sm={12}>
                        <div className='box__shadow__service__one'>
                            <h4>Globalised Airways connection</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry.</p>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12}>
                        <div className='box__shadow__service__two'>
                            <img className='img__roadways'
                                src='/images/service_bg_2.png' alt='' />
                            <div className='road__sec'>
                                <h4>Interlinked roadways Network</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.</p>
                            </div>

                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12}>
                        <div className='box__shadow__service__two'>
                            <img className='img__roadways'
                                src='/images/service_bg_3.png' alt='' />
                            <div className='road__sec'>
                                <h4>Internationalized overseas link</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12}>
                        <div className='box__shadow__service__two'>
                            <img className='img__roadways'
                                src='/images/service_bg_4.png' alt='' />
                            <div className='road__sec'>
                                <h4>Sophisticated warehouses</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div> */}

      <div className="services container" id="services-section">
        <div className="row">
          <p className="section-heading">Services</p>
          <hr className="section-underline" />
          <h1 className="services-h1">We offer different services</h1>
        </div>
        <div className="row gx-5">
          <div className="col-lg-6 grid1">
            <ScrollAnimation animateIn="fadeInLeft">
              <div className="row box-row">
                <div className="col-lg-6 p-0">
                  <img src="./images/national-transport-solutions.jpg" alt="" className="polygon-img" />
                </div>
                <div className="col-lg-6 polygon-div">
                  <h4>National Transport Solutions</h4>
                  <p>
                    General/Express Road Distribution, Intermodal, Full Truck
                    Loads, Local Transactional Taxi and Courier Services.
                  </p>
                  <Link
                    to="/services"
                    className="btn btn-sm btn-outline-danger"
                  >
                    Know More....
                  </Link>
                </div>
              </div>
            </ScrollAnimation>
          </div>

          <div className="col-lg-6 grid1">
            <ScrollAnimation animateIn="fadeInRight">
              <div className="row box-row">
                <div className="col-lg-6 p-0">
                  <img src="./images/temperature-control-solutions.jpg" alt="" className="polygon-img" />
                </div>
                <div className="col-lg-6 polygon-div">
                  <h4>Temperature Control Solutions </h4>
                  <p>
                    Chilled and Frozen National Distribution and Warehousing.
                  </p>
                  <Link
                    to="/services"
                    className="btn btn-sm btn-outline-danger"
                  >
                    Know More....
                  </Link>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <div className="row gx-5 py-3">
          <div className="col-lg-6 grid1">
            <ScrollAnimation animateIn="fadeInLeft">
              <div className="row box-row">
                <div className="col-lg-6 p-0">
                  <img src="./images/warehousing-solutions.jpg" alt="" className="polygon-img" />
                </div>
                <div className="col-lg-6 polygon-div">
                  <h4>4PL Warehousing Solutions</h4>
                  <p>
                    Order Fulfillment, Pick & Pack, Packaging , Labelling and National Distribution.
                  </p>
                  <Link
                    to="/services"
                    className="btn btn-sm btn-outline-danger"
                  >
                    Know More....
                  </Link>
                </div>
              </div>
            </ScrollAnimation>
          </div>
          <div className="col-lg-6 grid1">
            <ScrollAnimation animateIn="fadeInRight">
              <div className="row box-row">
                <div className="col-lg-6 p-0">
                  <img src="./images/global-logistics.jpg" alt="" className="polygon-img" />
                </div>
                <div className="col-lg-6 polygon-div">
                  <h4>Global Logistic Solutions</h4>
                  <p>
                    Fully supported and integrated Air and Sea services.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>
                  <Link
                    to="/services"
                    className="btn btn-sm btn-outline-danger"
                  >
                    Know More....
                  </Link>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      {/* <div className="blog">
        <h3>Blog</h3>
        <hr className="hr__small" />
        <h1>Know us even better!</h1>
        <div className="blog__main">
          <Container>
            <Row>
              <Col lg={8} md={8} sm={12}>
                <div className="blog__one">
                  <div className="blog__img">
                    <img
                      className="blog__image"
                      src="/images/blog_one.png"
                      alt=""
                    />
                  </div>
                  <div className="blog__desc">
                    <h4>Globalized seaways solution</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                      <b>Read More.</b>{" "}
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4} sm={12}>
                <div className="blog__two">
                  <div className="blog__img">
                    <img
                      className="blog__image__two"
                      src="/images/blog_two.png"
                      alt=""
                    />
                  </div>
                  <div className="blog__desc__two">
                    <h4>Globalized seaways solution</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                      <b>Read More.</b>{" "}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="blog__three" lg={8} md={8} sm={12}>
                <div className="blog__one">
                  <div className="blog__img">
                    <img
                      className="blog__image"
                      src="/images/blog_one.png"
                      alt=""
                    />
                  </div>
                  <div className="blog__desc">
                    <h4>Globalized seaways solution</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                      <b>Read More.</b>{" "}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div> */}
      {/* <Subscribe /> */}
    </div>
  );
}

export default Home;
