import React from "react";
import "./Services.css";
import Background from "./services-banner.jpg";
import PageBanner from "../PageBanner/PageBanner";
import { Container, Row, Col } from "react-bootstrap";
import Subscribe from "../Subscribe/Subscribe";
import ScrollAnimation from "react-animate-on-scroll";

function Services() {
  return (
    <div className="services">
      <PageBanner Background={Background} title="Our Services" style={{ backgroundImage: "url('./services-banner.jpg) !important"}}/>
      {/* <div className='elite__services__values'>
                <h3>Elite</h3>
                <hr className='hr__small' />
            </div> */}
      <div className="elite__logistics__services">
        <Container>
          <Row className="flex-style-row pt-0 pb-5">
            <Col lg={6} md={6} sm={12}>
              <ScrollAnimation animateIn="fadeInLeft">
                <img
                  className="logistics__service"
                  src="./images/national-transport.jpg"
                  alt="service"
                />
              </ScrollAnimation>
            </Col>
            <Col lg={6} md={6} sm={12} className="airline__service__col">
              <ScrollAnimation animateIn="fadeInRight">
                <h4>National Transport Distribution</h4>
                <p>
                  Elite Freight Solutions has forged strong alliances across the
                  Australian domestic carrier network providing door to door
                  customized solutions for all freight profiles and modes of
                  transport. Through our comprehensive carrier selection
                  process, we’ll align the carrier/s suitably qualified to meet
                  our customers specific requirements.
                </p>
              </ScrollAnimation>
            </Col>
          </Row>
          <hr className="service__line" />
          <Row className="roadways__service flex-style-row py-5">
            <Col lg={6} md={6} sm={12} className="airline__service__col">
              <ScrollAnimation animateIn="fadeInLeft">
                <h4>Temperature Control Solutions</h4>
                <p>
                  Elite Freight Solutions has become a market leader in the
                  management and transport of temperature- controlled product,
                  utilizing a host of accredited carriers and 3pl providers
                  throughout the country. Be it ambient, chilled, or frozen, we
                  have the network and tracking capabilities to ensure the
                  product is maintained at optimal conditions throughout its
                  entire journey.
                </p>
              </ScrollAnimation>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <ScrollAnimation animateIn="fadeInRight">
                <img
                  className="logistics__service"
                  src="./images/temperature-control.jpg"
                  alt="service"
                />
              </ScrollAnimation>
            </Col>
          </Row>
          <hr className="service__line" />
          <Row className="roadways__service flex-style-row py-5">
            <Col lg={6} md={6} sm={12}>
              <ScrollAnimation animateIn="fadeInLeft">
                <img
                  className="logistics__service"
                  src="./images/warehouse.jpg"
                  alt="service"
                />
              </ScrollAnimation>
            </Col>
            <Col lg={6} md={6} sm={12} className="airline__service__col">
              <ScrollAnimation animateIn="fadeInRight">
                <h4>4PL Warehouse Solutions</h4>
                <p>
                  Every customer has unique requirements, which is why we can
                  offer a suite of services for any scale operation, inclusive
                  of but not limited to; order fulfillment, pick &amp; pack,
                  packaging, labeling, and distribution supported by our
                  extensive carrier network. Our facilities are state of the
                  art, and all supported by WMS allowing full integration.
                </p>
              </ScrollAnimation>
            </Col>
          </Row>
          <hr className="service__line" />
          <Row className="roadways__service flex-style-row py-5">
            <Col lg={6} md={6} sm={12} className="airline__service__col">
              <ScrollAnimation animateIn="fadeInLeft">
                <h4>Global Logistics Solutions</h4>
                <p>
                  Our experienced team can build practical, modern, and
                  innovative solutions for all international supply chain
                  requirements. Our global partners enable us to provide an
                  end-to-end solution in and ever-changing global logistics
                  environment. This may incorporate import/export, via air or
                  sea, unpack, storage and final mile delivery.
                </p>
              </ScrollAnimation>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <ScrollAnimation animateIn="fadeInRight">
                <img
                  className="logistics__service"
                  src="./images/global-log.jpg"
                  alt="service"
                />
              </ScrollAnimation>
            </Col>
          </Row>
          <hr className="service__line" />
           <Row className="flex-style-row pt-0 pb-5">
            <Col lg={6} md={6} sm={12}>
              <ScrollAnimation animateIn="fadeInLeft">
                <img
                  className="logistics__service"
                  src="./images/national-transport-distribution.jpg"
                  alt="service"
                />
              </ScrollAnimation>
            </Col>
            <Col lg={6} md={6} sm={12} className="airline__service__col">
              <ScrollAnimation animateIn="fadeInRight">
                <h4>Contract Logistics/Permanent Hire</h4>
                <p>
                  Elite Freight Solutions provide a national, permanent fleet solution across varying fleet compositions with experienced drivers. Elite will manage the entire process from recruitment, induction and training customised to our customer’s specific needs, allowing you the customer to focus on your core business activities.
                </p>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </div>

      <Subscribe />
    </div>
  );
}

export default Services;
