import React, { useRef, useState, Fragment } from 'react'
import "./Form.css";
import { Col, Container, Row } from "react-bootstrap";
import { send } from 'emailjs-com';
import PhoneCode from 'react-phone-code';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from "react-router-dom";
toast.configure();

const Form = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);  
  const selectCode = useRef(null);
  const [toSend, setToSend] = useState({
    to_name: "Elite Team",
    full_name: '',
    country_code: "",
    contact_number: "",
    email_address: '',
    message: '',
    reply_to: process.env.REACT_APP_ADMIN_EMAIL,
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send(
      process.env.REACT_APP_FORMIK_SERVICE_ID,
      process.env.REACT_APP_FORMIK_TEMPLATE_ID,
      toSend,
      process.env.REACT_APP_FORMIK_USER_ID
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
    setIsSubmitted(true);
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  // const options = useMemo(() => countryList().getData(), [])

  const notify = ()=>{
        toast.success('Message Sent', {
         // Set to 15sec
         position: toast.POSITION.TOP_CENTER, autoClose:5000})
    }

  return (
  <>
    {!isSubmitted ? (
    <div className="form py-5">
      <Container>
        <div className="form__head flex-style" style={{ flexDirection: "row" }}>
          {/* <PermPhoneMsgIcon className="phone__msg__icon" /> */}
          <div className="form__text">
            <h6>You are just a few steps away</h6>
            
          </div>
        </div>
        <div className="contact__form">
          <form onSubmit={onSubmit}>
            <Row>
              <Col lg={6} md={6} sm={12} className="flex-style gmail-left">
                <img src="./images/gmail.png" alt="" width={"192px"} />
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Row>
                  <input
                  type="text"
                  placeholder="Full Name"
                  id="full_name"
                  name="full_name"
                  onChange={handleChange}
                  value={toSend.full_name}
                  className="contact__field"
                />{" "}
                <br />
              
                </Row>
                <Row className='flex-style mt-2' style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                  <Col lg={4} className="pt-3">
                    <PhoneCode
                      ref={selectCode}
                      onSelect={code => {
                        console.log(code);
                        return setToSend((prevState) => {
                        return { ...prevState, country_code: code }
                      })
                      }
                      }
        showFirst={['AU', 'IN']}
        defaultValue='select county'
        id='country_code'
                      name='country_code'
        className='contact__field__number'
                      optionClassName=''
                      value={toSend.country_code}
                      onChange={handleChange}
    />
                  </Col>
                  <Col lg={8}>
                  <input
                  type="number"
                  placeholder="Phone Number"
                  id="contact_number"
                  name="contact_number"
                  onChange={handleChange}
                  value={toSend.contact_number}
                  className="contact__field__number"
                />
                  </Col>
                </Row>
                <input
                  type="email"
                  placeholder="Email"
                  id="email_address"
                  name="email_address"
                  onChange={handleChange}
                  value={toSend.email_address}
                  className="contact__field__email"
                />
                <textarea
                  placeholder="your message.."
                  name="message"
                  id="message"
                  onChange={handleChange}
                  value={toSend.message}
                  className="contact__field__text"
                />
                <button type="submit" className="contact__button">
                  Send
                </button>
              </Col>
            </Row>
          </form>
        </div>
      </Container>
        </div>
        ) : (
        <Redirect to="/thankyou" />
      )}
      </>
  );
};

export default Form;
