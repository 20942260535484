import React, { useEffect, useState } from 'react'
import './ScrollToTop.css'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function ScrollToTop(){
    const [isVisible,setIsVisible] = useState(false);

    const toggleVisibility = () =>{
        if(window.pageYOffset > 300){
            setIsVisible(true);
        }else{
            setIsVisible(false);
        }
    }

    const GoToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    useEffect(()=>{
        window.addEventListener("scroll",toggleVisibility);
    },[]);

    return(
        <div className='scroll__to__top'>
            {isVisible && 
                <div onClick={GoToTop} className='go__to__top'>
                    <ArrowUpwardIcon className='go__top__button' />
                </div>
            }
        </div>
    );
}

export default ScrollToTop;