import React from "react";
import "./Banner.css";
import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav } from "react-bootstrap";
import PhoneIcon from "@mui/icons-material/Phone";

function Banner() {
  return (
    <>
          <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container fluid>
              <Navbar.Brand>
                <Link to="/">
                  {/* <img className='elite__logo' src='/images/Elite-BC.webp' alt='logo' /> */}
                  <img
                    className="elite__logo"
                    src="./images/elitelogo.jpg"
                    alt="logo"
                  />
                </Link>
                <a className="hyper-login" target="_blank" href="http://www.flagshiptms.com/">
                  <img
                className="elite__logo flag_ship"
                src="./images/flagship-logo.png"
                alt="logo"
              />
              </a>
            
            <a className="hyper-login" target="_blank" href="http://www.flagshiptms.com/">Flagship Log In</a>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto"></Nav>
                <Nav>
                  <Link to="/" className="home__nav active">
                    Home
                  </Link>
                  <Link to="/about" className="home__nav">
                    About
                  </Link>
                  <Link to="/services" className="home__nav">
                    Services
                  </Link>
                  <Link to="/technology" className="home__nav">
                    Technology
                  </Link>
                  <Link to="/network" className="home__nav">
                    Network
                  </Link>
                  <Link to="/contact" className="home__nav">
                    Contact
                  </Link>
                  <a href="tel:+61392420143" className="home__nav">
                    <button className="btn btn-sm btn-danger btn-contact p-2">
                    +61 3 9242 0143
                    </button>
                  </a>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

        <div id="landing-wrapper" className="container-fluid">
        <div className="hero">
          
          <div className="caption">
            <h1 className="caption-h1 pb-2 px-3">Integrated Logistics</h1>
            <p className="caption-p px-4 py-3" style={{textAlign: 'center'}}>
              Unique end to end supply chain solutions <br/> through
              interconnected networks
            </p>
            {/* <a href="#services-section" className=" ">
              <button className="btn btn-sm btn-danger btn-contact p-3">
                Explore More
              </button>
            </a> */}
            <Link to="/services" className="btn btn-sm btn-outline-danger"> Explore More </Link>
          </div>

          {/* <Carousel variant="dark">
                <Carousel.Item interval={4000}>
                    <img
                        className="d-block w-100"
                        src="/images/carsoul_img2.png"
                        alt="First slide"
                    />
                    <Carousel.Caption>

                        <div className='banner__head'>
                            <h1>INTEGRATED LOGISTICS</h1>
                            <p>Unique end to end supply chain solutions through interconnected networks
                            </p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                    <img
                        className="d-block w-100"
                        src="/images/home_banner.png"
                        alt="Second slide"
                    />
                    <Carousel.Caption>

                        <div className='banner__head'>
                        <h1>INTEGRATED LOGISTICS</h1>
                            <p>Unique end to end supply chain solutions through interconnected networks
                            </p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel> */}
        </div>
      </div>
    </>
  );
}

export default Banner;
